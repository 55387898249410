<template>
  <div class="qeustion">
    <div class="_top">
      <div class="_left">
        <div class="title">
          怎样在碎片化的时间最大化的提高学习效率？<em @click="this.$router.push('/')">来自 计算机二级证书</em>
        </div>
        <div class="text">
          全国计算机等级考试（NationalComputerRankExamination，简称 NCRE），是为了考查考生的计算机应用知识和技能而设置的。经教育部批准，由教育部考试中心主办，要求参试者具有计算机应用知识以及办公软件高级应用能力…
        </div>
        <div class="add" @click="this.active = !this.active"><img src="../assets/images/icon4.png" /><em>{{ !this.active ? '我来' : '收起' }}回答</em></div>
      </div>
      <div class="_right">
        44个关注 · 4969万浏览
      </div>
    </div>
    <div class="wrap">
      <div class="_left">
      <Comment v-if="active"/>
      <div class="center">
        <div class="title">
          <label>5个回答</label>
          <label>默认排序</label>
        </div>
        <ListArticle />
        <div class="more">
          加载更多...
        </div>
      </div>
    </div>
    <div class="_right">
      <Recommend />
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Recommend from '../components/Recommend.vue'
import ListArticle from '../components/ListArticle.vue'
import Comment from '../components/Comment.vue'

export default {
  name: 'Home',
  components: {
    Recommend,
    ListArticle,
    Comment
  },
  data () {
    return {
      active: 1
    }
  }
}
</script>

<style lang="scss">
.qeustion {
  width: 1000px;
  margin: 16px auto 0 auto;
  ._top {
    background: #ffffff;
    border-radius: 8px;
    padding: 28px;
    margin-bottom: 12px;
    display: flex;
    .title {
      font-size: 28px;
      line-height: 1.389;
      font-weight: 600;
      color: #262626;
      em {
        font-style: normal;
        cursor: pointer;
        color: #999;
        font-weight: normal;
        font-size: 12px;
      }
    }
    .text {
      line-height: 24px;
      font-size: 12px;
      color: #999;
      margin-top: 12px;
    }
    ._right {
      margin: 12px 22px 12px 62px;
      color: #999;
    }
    .add {
      margin-top: 18px;
      width: 96px;
      background: #417ff9;
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      &:hover {
        background: #376fdd;
      }
    }
    img {
      width: 14px;
      height: 14px;
      vertical-align: middle;
      margin-right: 3px;
    }
    em {
      vertical-align: middle;
      font-style: normal;
      color: #ffffff;
      font-size: 13px;
      height: 36px;
      line-height: 36px;
    }
  }
  .wrap {
    display: flex;
    .comment {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;
    }
  }
  ._left {
    width: 694px;
  }
  ._right {
    flex: 1;
    margin-left: 12px;
  }
  .center {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    > .title {
      height: 44px;
      border-bottom: 1px solid #eeeeee;
      padding: 0 12px;
      display: flex;
      label {
        cursor: pointer;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        margin: 0 12px;
        display: inline-block;
        &.select {
          border-bottom: 3px solid #417ff9;
        }
        &:first-child {
          flex: 1;
        }
        &:last-child {
          width: 80px;
          text-align: right;
        }
      }
    }
    .more {
      font-size: 12px;
      color: #999;
      padding: 22px 22px 42px;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
